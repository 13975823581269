import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
// import * as serviceWorker from "./serviceWorker";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Work from "./pages/Work";
import BellyBombz from "./pages/BellyBombz";
import Remoov from "./pages/Remoov";
import OneFlow from "./pages/1Flow";
import BootcampBuilder from "./pages/BootcampBuilder";


// import About from "./pages/About";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import thumbData from "./assets/thumb-data.json";

thumbData.forEach((item) => {
  item.image = process.env.PUBLIC_URL + "/images/" + item.image;
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
<Router>
    <Routes>
        <Route path='/' element={<Work thumbData={thumbData}/>} />
        <Route path='/BellyBombz' element={<BellyBombz image={thumbData[1].image} alt={thumbData[1].alt}/>} />
        <Route path='/Remoov' element={<Remoov image={thumbData[2].image} alt={thumbData[2].alt}/>} />
        <Route path='/1Flow' element={<OneFlow image={thumbData[0].image} alt={thumbData[0].alt}/>} />

        <Route path='/BootcampBuilder' element={<BootcampBuilder image={thumbData[3].image} alt={thumbData[3].alt}/>} />
        {/* <Route path='/about' element={<About/>}/> */}
      </Routes>
  </Router>,  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();
