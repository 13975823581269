import '../ProjectPages.css';
import NBar from "../components/NBar";


export default function OneFlow(props) {

    return (

        <div className="1Flow">
            <NBar />
            <h1 className="display-3 text-center padding-under-nbar fix-width mg-center">1Flow</h1>
            <p className="fw-semibold text-center bright-orange fix-width mg-center">Web design for an in-app survey tool for tech startups.</p>
            <img src={props.image} alt={props.alt} className="section mg-center fix-width img-frame-rounded"></img>

            <div className="section lighter-bg">
                <div className="flex-standard fix-width mg-center wider-column-gap">
                    <div className="project-stats">
                        
                        <p className="fw-semibold smaller-header bright-orange ">Skills</p>
                        <p>Prototyping<br>
                        </br>
                            User Testing<br></br>Iterative Design</p>
                            

                        <p className="fw-semibold smaller-header bright-orange">Tools</p>
                        <p >Figma<br></br>Usertesting.com</p>
                        
                        <p className="fw-semibold smaller-header bright-orange">Team</p>
                        <p>Member 1<br></br>Member 2<br></br>Member 3<br></br>Member 4</p>
                        
                        <p className="fw-semibold smaller-header bright-orange">Year</p>
                        <p className="no-bottom-margin">2022</p>
                        
                    </div>
                    <p className="project-description">1Flow is a platform that allows developers to receive in-platform feedback on mobile and web apps to facilitate quick user testing and turnaround. As a group of UI/UX students at Brown University, our team of four set out to create a web interface that makes the process of creating and analyzing user tests easy for developers.<br></br><br></br>
                        We began by identifying the needs of the typical user, created quick sketches that reflect those needs, then proceeded to protoype the interface. We iterated on our original design <span className="fw-semibold">3</span> times following peer critiques and online user testing results to address usability concerns.
                    </p>
                </div>
            </div>

            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">Identify<span className="fst-italic">ing</span> User Needs</h2>
                <div className="section-body mg-center fix-width">
                    <p className="fw-semibold smaller-header bright-orange">Brainstorming</p>
                    <p>Our team conducted a lightning brainstorming session during which we identified key user needs.
                        A typical developer using 1Flow would want to:
                    </p>
                    <ul>
                        <li>Create user tests specific for their application(s).</li>
                        <li>Edit created user tests.</li>
                        <li>Easily view and understand user test results.</li>
                        <li>View individual responses in cases of atypical feedback.</li>
                        <li>View the in-app test interface from the user's point of view to make necessary changes.</li>
                    </ul>
                    <p className="fw-semibold smaller-header bright-orange">Sketching</p>
                    <p>Individually, we came up with quick interface sketches <span className="fst-italic">(pictured below)</span> to address the user needs mentioned above. We then analyzed the similarities and differences between our sketches to consolidate our interface into five main pages: a home page, a dashboard, an "edit test" page, a results page, and a user view page.</p>
                </div>


                <div className="flex-standard section-body mg-center fix-width">
                    <img src={process.env.PUBLIC_URL + "/images/1Flow-sketch-1.png"} alt="" className="img-frame-rounded flex-section-half"></img>
                    <img src={process.env.PUBLIC_URL + "/images/1Flow-sketch-2.png"} alt="" className="img-frame-rounded flex-section-half"></img>
                    <img src={process.env.PUBLIC_URL + "/images/1Flow-sketch-3.png"} alt="" className="img-frame-rounded flex-section-half"></img>
                    <img src={process.env.PUBLIC_URL + "/images/1Flow-sketch-4.png"} alt="" className="img-frame-rounded flex-section-half"></img>
                </div>







            </div>


            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">Iter<span className="fst-italic">ative</span> Designing</h2>
                <div className="flex-standard section-body mg-center fix-width wide-column-gap flex-reverse-wrap">
                    <div className="flex-standard-vert flex-section-half">

                        <p className="fst-italic less-gap caption-text">Home page, Dashboard page, and User View page, second iteration.</p>
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-pre-1.png"} alt="" className="img-frame-rounded"></img>
                    
                    <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-pre-2.png"} alt="" className="img-frame-rounded"></img>
                    <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-pre-3.png"} alt="" className="img-frame-rounded"></img>
                </div>



                <div className="flex-section-half section-body">
                    <p>
                        Adhering closely to the initial sketches, we designed a high-fidelity Figma prototype based on our sketches in order to conduct user tests to uncover pain points and improve on the design.

                    </p>

                    <p className="fw-semibold smaller-header bright-orange">Peer Critiques</p>
                    <p>
                        We presented our first high-fidelity prototype of the interface to our fellow UI/UX student peers as well as a professional UX Designer. They were given time to interact with our Figma prototype. We received the following feedback:
                    </p>
                    <ul>
                        <li>The page title "My Apps" is not indicative of its purpose.</li>
                        <li>The page title "Edit [App Name]" is not indicative of its purpose.</li>
                        <li>Clicking on the 1Flow logo in the navigation bar should take you to the "My Apps" dashboard rather than the home page.</li>
                        <li>Volume of information on the Stats page is overwhelming.</li>
                        <li>Unclear when the user is in "user view" - just looks like the website's interface.</li>
                    </ul>
                    <p className="fw-semibold smaller-header bright-orange">Usertesting.com</p>
                    <p>Following the peer review, we created another iteration of our prototype and conducted three user tests via Usertesting.com, a remote user testing platform. Users were instructed to sign in, edit the app Google's questionnaire, and review the results of the user tests for Google. We viewed screen recordings of their user test sessions, listened to their narration, and read their written feedback to consolidate their critiques into the following:</p>
                    <ul>
                        <li>"Edit" button on "My Apps" page is non-descriptive.</li>
                        <li>The design of the "User View" page is inconsistent with the other pages.</li>
                        <li>Design of the home page is not inviting.</li>
                        <li>Text boxes are small, which would make typed text difficult to read.</li>
                        <li>User test in "User View" looks like it's part of the website's page.</li>
                    </ul>
                    </div>

                </div>
            </div>


            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">Final Iterat<span className="fst-italic">ion</span></h2>
                <p className="text-center">View and interact with the Figma prototype <span className="fw-semibold bright-orange"><a href="https://www.figma.com/proto/lo3L5dFhNPysLhrCuuoyI1/1Flow-(4me)?page-id=0%3A1&node-id=1%3A388&viewport=528%2C575%2C0.06&scaling=min-zoom&starting-point-node-id=1%3A388"><u>[here]</u></a></span>.</p>
                <div className="section-body lighter-bg ">
                    <div className="flex-standard mg-center fix-width">
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-laptop-1.png"} alt="" className="flex-section-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-laptop-2.png"} alt="" className="flex-section-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-laptop-3.png"} alt="" className="flex-section-third"></img>
                    </div>
                </div>
                <div className="section-body fix-width mg-center">
                    <p className="fw-semibold smaller-header bright-orange">Addressing Pain Points</p>
                    <p>
                        We consolidated the feedback to brainstorm and implement actionable changes to our interface.
                    </p><p className="fw-semibold smaller-header bright-orange">1. The Home Page</p>

                    <ul>
                        <li>Added an eye-catching image that abstractly reflects the branding and purpose of 1Flow.</li>
                        <li>Added a "How It Works" link that can help clarify the purpose of this platform.</li>
                    </ul>


                    <div className="flex-standard section-body">
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-final-1.png"} alt="" className="img-frame-sharp flex-section-half"></img>
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-final-2.png"} alt="" className="img-frame-sharp flex-section-half"></img>
                    </div>


                    <p className="fw-semibold smaller-header bright-orange section-body">2, 3, 4. The Dashboard, The "Edit Test" Page, and The Results Page</p>

                    <ul>
                        <li>"My Apps" title is now "User Test" to clarify the page's function. This also helps clarify that the "Edit" button refers to editing the user test.</li>
                        <li>"Edit [App Name]" title is now "Edit User Test for [App Name]" to clarify the page's function.</li>
                        <li>Consolidated result graphs into scrollable cards, and questionnaire results are now collapsible on "Results" page to take up less vertical space.</li>
                        <li>Larger text boxes to improve readability.</li>
                    </ul>

                    <div className="flex-standard section-body">

                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-final-3.png"} alt="" className="img-frame-sharp flex-section-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-final-4.png"} alt="" className="img-frame-sharp flex-section-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-final-5.png"} alt="" className="img-frame-sharp flex-section-third"></img>
                    </div>


                    <p className="fw-semibold smaller-header bright-orange section-body">5. The User View Page</p>
                    <ul>
                        <li>User view is now enclosed in a frame so that it's clear that the viewer is still on the 1Flow website.</li>
                        <li>User test is now presented with a fixed footer that includes the 1Flow logo along with a white overlay to differentiate the user test from the application.</li>

                    </ul>

                    <div className="flex-standard section-body">

                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-final-6.png"} alt="" className="img-frame-sharp flex-section-half"></img>
                        <img src={process.env.PUBLIC_URL + "/images/1Flow-hifi-final-7.png"} alt="" className="img-frame-sharp flex-section-half"></img>
                    </div>


                </div>
            </div>
            <div className="footer"></div>



        </div >
    );
}