import '../ProjectPages.css';
import NBar from "../components/NBar";


export default function BellyBombz(props) {

    return (

        <div className="BellyBombz">
            <NBar />
            <h1 className="display-3 text-center padding-under-nbar fix-width mg-center">Belly Bombz Kitchen</h1>
            <p className="fw-semibold text-center bright-orange fix-width mg-center">Web interface responsive redesign for a novelty restaurant.</p>
            <img src={props.image} alt={props.alt} className="section mg-center fix-width img-frame-rounded"></img>

            <div className="section lighter-bg">
                <div className="flex-standard fix-width mg-center">
                    <div className="project-stats">
                        <p className="fw-semibold smaller-header bright-orange">Skills</p>
                        <p>Prototyping<br>
                        </br>
                            Front end development<br></br>Usability analysis<br></br>Accessibility analysis</p>

                        <p className="fw-semibold smaller-header bright-orange">Tools</p>
                        <p>Balsamiq<br></br>Figma<br></br>HTML5<br></br>CSS Bootstrap</p>
                        <p className="fw-semibold smaller-header bright-orange">Year</p>
                        <p className="no-bottom-margin">2022</p>
                    </div>
                    <p className="project-description">Belly Bombz is a restaurant located in Southern California that specializes in Korean cuisine-inspired foods. As a regular customer, I've become very familiar with their website's many usability issues and launched a personal project to design and program a new responsive interface for the restaurant's homepage. My goal was to create an interface that makes it simpler for the typical user to view the menu and/or place an order online.</p>
                </div>
            </div>

            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">Identify<span className="fst-italic">ing</span> Issues with the Current Interface</h2>
                <div className="flex-standard section-body mg-center fix-width">
                    <div className="flex-section-image">
                        <p className="fst-italic caption-text">Belly Bombz's current design, mobile view.</p>
                        <img src={process.env.PUBLIC_URL + "/images/BB-original.jpg"} alt="" className="img-frame-rounded"></img></div>
                    <div className="flex-section-text section-body">
                        <p className="fw-semibold smaller-header bright-orange">Usability</p>
                        <p>Assuming that the typical user is visiting the page to view the restaurant's menu or place an order online, the current interface doesn't facilitate this process.</p>
                        <ul>
                            <li>Standard font size is hard to read on mobile.</li>
                            <li>Unintuitive content and order of elements in navigation bar.</li>
                            <li>Misleading linked header images.</li>
                            <li>Uninformative, repetitive image carousel.</li>
                            <li>Images stack at certain viewport widths, taking up excessive vertical space.</li>
                            <li>"Order Online" button is not particularly accessible, especially when user scrolls down to the menu.</li>
                        </ul>
                        <p className="fw-semibold smaller-header bright-orange">Accessibility</p>
                        <p>The page is image-heavy, but lacks helpful alt tags. Many essential text-heavy areas, such as the catering menu, are presented through images, making it impossible for screenreaders to interpret.</p>
                    </div>
                </div>
            </div>


            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header"> Low Fidelity Proto<span className="fst-italic">typing</span></h2>
                <div className="section-body">
                    <div className="flex-standard mg-center fix-width">
                        <img src={process.env.PUBLIC_URL + "/images/BB-lofi-desktop.png"} alt="" className="flex-section-half"></img>
                        <div className="flex-standard-vert flex-section-half">
                        <div className = "extra-bottom-padding">
                        <p className="fw-semibold smaller-header bright-orange">Solutions</p>
                        <p>Before fleshing out the visual design of the final interface, I created low fidelity prototypes in Balsamiq to address the usability issues:</p>
                        <ul>
                            <li>"Order Online" button now has <span className="fw-semibold">3</span> access points: in the navbar, after the restaurant introduction, and below the menu.</li>
                            <li>Order of navbar content has been rearranged to prioritize most frequently visited tabs.</li>
                            <li>Multiple linked header images have been replaced by a single image that displays a variety of food items.</li>
                            <li>Menu image has been replaced with text menu so that it is readable for screenreaders.</li>
                            <li>Food images now include informational labels so the user knows what dish they are looking at.</li>
                        </ul>
                    </div>


                            <img src={process.env.PUBLIC_URL + "/images/BB-lofi-tablet.png"} alt="" className=""></img>
                            <img src={process.env.PUBLIC_URL + "/images/BB-lofi-mobile.png"} alt="" className="smaller-width"></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">Implementing the <span className="fst-italic">Solut</span>ion</h2>
                <p className="text-center">View the redesigned responsive website, live <span className="fw-semibold bright-orange"><a href="https://dd7bya.github.io/redesign-page-deployed/"><u>[here]</u></a></span>.</p>
                <div className="section-body lighter-bg ">
                    <div className="flex-standard mg-center fix-width">
                        <img src={process.env.PUBLIC_URL + "/images/BB-hifi-laptop-1.png"} alt="" className="flex-section-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/BB-hifi-laptop-2.png"} alt="" className="flex-section-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/BB-hifi-laptop-3.png"} alt="" className="flex-section-third"></img>
                    </div>
                </div>

                <div className="flex-standard section-body mg-center fix-width">
                    <div className="flex-section-image">
                        <p className="fst-italic caption-text">Belly Bombz's redesign, mobile view.</p>
                        <img src={process.env.PUBLIC_URL + "/images/BB-hifi-mobile.png"} alt="" className="img-frame-rounded"></img>
                    </div>
                    <div className="flex-section-text section-body">
                    <p className="fw-semibold smaller-header bright-orange">Final Design</p>
                    <p>I proceeded to create a high-fidelity prototype of the new interface in Figma, and built the page using HTML5 and Bootstrap CSS. Alt tags were added to each image to increase accessibility.</p>
                    <p className="fw-semibold smaller-header bright-orange">Style Guide</p>
                    <p>I created a style guide based on the restaurant's current branding. The loud, red color reflects the intensity of the restaurant's name, while the yellow color is taken from the typical color palette of their dishes.</p>
                    <img src={process.env.PUBLIC_URL + "/images/BB-style-guide.png"} alt="" className="smaller-height"></img>
                        
                
                    </div>
                </div>
            </div>
            <div className="footer"></div>



        </div>
    );
}