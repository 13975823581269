import '../NBar.css';

export default function NBar() {
  return (
    <div className="NBar">
      <div className="fix-width-wider nBar-content-flex mg-center">
      <a href="/"><span className="secondaryFont nBar-text"><span className="secondaryFont fst-italic">Anon</span>ymous<span className="secondaryFont fst-italic">!</span></span></a>
      <div className="links flex-standard nBar-links-flex">
        <a href="/" className="fw-semibold">Work</a>
        <a href="/about" className="fw-semibold">About</a>
      </div>
      </div>
    </div>

  );
}