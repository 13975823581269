import '../HomeThumb.css';
import { NavLink } from "react-router-dom";


export default function HomeThumb(props) {
    var stringCounter = 0;
    const link = "/" + props.link;

    return (
        <div className="HomeThumb flex-thumbnails">
            <NavLink to={link}>
            <h2 className="display-6 secondaryFont text-start" href={link}>
                
                {props.nameList.map((namePiece) => {
                    const pieceString = namePiece.toString();
                    if (stringCounter % 2 == 0) {//is even
                        stringCounter++;
                        return (pieceString)
                    }
                    else { //is odd
                        stringCounter++;
                        return (<span className="fst-italic">{pieceString}</span>)
                    }
                }
                )
                } &rarr; </h2></NavLink>

                <p>{props.description}</p>
                <NavLink to={link}>
            <img src={props.image} alt={props.altTag} className="img-frame-rounded" />
            </NavLink>
            
        </div>
    );
}