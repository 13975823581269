import '../ProjectPages.css';
import NBar from "../components/NBar";


export default function Remoov(props) {

    return (

        <div className="Remoov">
            <NBar />
            <h1 className="display-3 text-center padding-under-nbar fix-width mg-center">Remoov</h1>
            <p className="fw-semibold text-center bright-orange fix-width mg-center">Graphic design work for a tech-enabled decluttering and reselling service.</p>
            <img src={props.image} alt={props.alt} className="section mg-center fix-width img-frame-rounded"></img>

            <div className="section lighter-bg">
                <div className="flex-standard fix-width mg-center">
                    <div className="project-stats">
                        <p className="fw-semibold smaller-header bright-orange">Skills</p>
                        <p>Prototyping<br>
                        </br>
                            Marketing graphic design<br></br></p>

                        <p className="fw-semibold smaller-header bright-orange">Tools</p>
                        <p>Figma<br></br>Illustrator<br></br>InDesign<br></br>Canva</p>
                        <p className="fw-semibold smaller-header bright-orange">Year</p>
                        <p className="no-bottom-margin">2022 - 2023</p>
                    </div>
                    <p className="project-description">Remoov is a pick-up and reselling service that eliminates the hassle of disposing of items for customers. They provide the means and expertise to make decluttering a simple process. In the process, they are able to reduce waste, increase item reuse, and provide customers with a fair value back for their items. They partner with <span className="fw-semibold">The Local Flea</span>, a consignment store with a large selection of quality, pre-owned items, to get their customers' items resold.<br></br><br></br>As a Design Intern, I was tasked with a wide variety of projects, many of which had the goal of improving the company's SEO strategy and improving connections with partners.
                    </p>
                </div>
            </div>

            {/* welcome kit, brochure, flyer */}

            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">The Product <span className="fst-italic">Style Guide</span></h2>
                <div className="section-body lighter-bg ">
                    <div className="flex-standard mg-center fix-width">
                        <img src={process.env.PUBLIC_URL + "/images/remoov-TLF-PCExample-1.png"} alt="" className="flex-section-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-TLF-PCExample-2.png"} alt="" className="flex-section-third"></img>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-TLF-PCExample-3.png"} alt="" className="flex-section-third"></img>
                    </div>
                </div>



                <div className="flex-standard section-body mg-center fix-width">
                    <div className="flex-section-image">
                        <p className="fst-italic caption-text">Templated prototype of the "Product Style Guide" page.</p>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-TLF-PCTemplate.png"} alt=""></img></div>
                    <div className="flex-section-text section-body">
                        <p className="fw-semibold smaller-header bright-orange">Primary Goals</p>
                        <p>The "Product Style Guide" is a category of webpages to be deployed on The Local Flea's website. The main goals of this project are to:</p>
                        <ul>
                            <li>Inform readers of the best ways to style their furniture items within their space.</li>
                            <li>Drive organic traffic to The Local Flea's website.</li>
                            <li>Increase users' session durations, or time spent on the website.</li>
                        </ul>
                    
                        As the designer, I was tasked with prototyping the pages and coming up with a workflow that would allow for quick turnaround.<br></br><br></br>
                        <p className="fw-semibold smaller-header bright-orange">Designing the Template</p>
                        <p>Because we knew from the start that dozens of these pages would eventually be launched on the website, I proposed creating a template <span className="fst-italic">(pictured left)</span> that would make designing these pages more efficient for the designer, copywriter, and engineers. This template was created entirely using Figma components, which will make creating pages straightforward for future interns and designers working on this project while preventing deviations from the established layout.</p>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">The <span className="fst-italic">Wel</span>come Kit</h2>
                <div className='flex-standard section-body mg-center fix-width wide-column-gap'>
                    <div className="flex-standard-vert flex-section-half">
                        <img src={process.env.PUBLIC_URL + "/images/remoov-welcome-kit-1.png"} alt="" className="img-frame-rounded"></img>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-welcome-kit-2.png"} alt="" className="img-frame-rounded"></img>
                        <img src={process.env.PUBLIC_URL + "/images/remoov-welcome-kit-3.png"} alt="" className="img-frame-rounded"></img>
                    </div>
                    <div className="flex-section-half">
                        <p className="fw-semibold smaller-header bright-orange">Primary Goals</p>
                        <p>The project to create a welcome kit for partners was launched in order to demonstrate Remoov's commitment to the partnership and provide a way to introduce partners to the company. It includes advice and tips for informing their clients of Remoov's services and links to access digital files for marketing.<br></br><br></br>As the designer, I was tasked with visually presenting a wide variety of in-depth information in a cohesive and easily understandable way.</p>
                        <p className="fw-semibold smaller-header bright-orange">Iterative Designing</p>
                        <p>During the design process, I collaborated closely with our team's copywriter and the CEO to be certain that the material accurately represented the company and its values. With an abundance of feedback, I iterated on the original design close to a dozen times to get to the final result.</p>
                    </div>

                </div>

            </div>

            <div className="section">
            <h2 className="display-6 secondaryFont text-center secondary-header">Misc<span className="fst-italic">ellaneous</span> Design Work</h2>
            <div className="flex-standard-vert section-body mg-center fix-width">
                <div className="flex-standard">
            <img src={process.env.PUBLIC_URL + "/images/thumb-remoov.png"} alt="" className="img-frame-rounded flex-section-half"></img>
            <img src={process.env.PUBLIC_URL + "/images/remoov-brochure-inner.jpg"} alt="" className="img-frame-rounded flex-section-half"></img>
            </div>
            
            <div className="flex-standard">
            <img src={process.env.PUBLIC_URL + "/images/remoov-card-1.jpg"} alt="" className="img-frame-rounded flex-section-half"></img>
            <img src={process.env.PUBLIC_URL + "/images/remoov-card-2.jpg"} alt="" className="img-frame-rounded flex-section-half"></img>
            </div>


            <div className="flex-standard">
            <img src={process.env.PUBLIC_URL + "/images/remoov-flyer-2.jpg"} alt="" className="img-frame-rounded flex-section-half"></img>
            <img src={process.env.PUBLIC_URL + "/images/remoov-flyer-1.jpg"} alt="" className="img-frame-rounded flex-section-half"></img>
            </div>

            </div>


            </div>

            <div className="footer"></div>

        </div>
    );
}