import '../Work.css';

import HomeThumb from "../components/HomeThumb.js";
import NBar from "../components/NBar";


export default function Work(props) {
    var stringCounter = 0;

    return (
    <div className="work">
      <NBar />
      <h1 className="display-3 text-start padding-under-nbar fix-width-wider mg-center">Hi, my name is <span className="secondaryFont display-1"><span className="secondaryFont fst-italic">Anon</span>ymous<span className="secondaryFont fst-italic">!</span></span>
      <br></br>
      I'm a <span className="fw-medium bright-orange">UI designer</span> with a background in graphic design.</h1>

      {/* <NavLink className="navbar-brand" to="/about">
        About
          </NavLink> */}

      <div className="Thumbnails fix-width-wider mg-center flex-standard thumb-gap">
        {props.thumbData.map((item) => {
          return (<HomeThumb nameList={item.nameList} description={item.description} image={item.image} altTag={item.altTag} link={item.link}/>);
        })}
      </div>
      <div className="footer"></div>
      
    </div>
    );
}