import '../ProjectPages.css';
import NBar from "../components/NBar";


export default function BootcampBuilder(props) {

    return (

        <div className="BootcampBuilder">
            <NBar />
            <h1 className="display-3 text-center padding-under-nbar fix-width mg-center">Bootcamp Builder</h1>
            <p className="fw-semibold text-center bright-orange fix-width mg-center">Interactive workout builder, built with React JS.</p>
            <img src={props.image} alt={props.alt} className="section mg-center fix-width img-frame-rounded"></img>

            <div className="section lighter-bg">
                <div className="flex-standard fix-width mg-center">
                    <div className="project-stats">
                        <p className="fw-semibold smaller-header bright-orange">Skills</p>
                        <p>
                            Front end development</p>

                        <p className="fw-semibold smaller-header bright-orange">Tools</p>
                        <p>React JS<br></br>HTML5<br></br>CSS Bootstrap</p>
                        <p className="fw-semibold smaller-header bright-orange">Year</p>
                        <p className="no-bottom-margin">2022</p>
                    </div>
                    <div className="project-description">
                    <p >Bootcamp Builder is a work-in-progress passion project that was born out of my love of fitness. I designed and programmed an interface that I personally would like to see developed into a fully-functioning app. Bootcamp Builder allows the user to build their own exercise routine from a library of exercises and keep track of relevant metrics.<br></br><br></br>
                        I took this project as an opportunity to expand my React JS skills and set the following goals:
                </p>
                <ul>
                    <li>Learn to divide the interface into appropriate components.</li>
                    <li>Learn to pass relevant information between components.</li>
                    <li>Use state hooks to update the interface upon user interaction.</li>
                </ul>
                </div>
                </div>
            </div>

            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">Identify<span className="fst-italic">ing</span> User Needs</h2>
                <div className="section-body fix-width mg-center">
                    <p>
                        Prior to beginning on the interface design, I spent time considering what the typical user might need from Bootcamp Builder.
                        Using myself as a frame of reference for a typical user, I decided that the following functions were essential to the application:
                    </p>
                    <ul>
                    <li>Display a database of exercises to choose from.</li>
                        <li>Add and delete exercises from a "cart" that stores the exercises they are planning to include in their routine.</li>
                        <li>Sort the database of exercises by relevant metrics.</li>
                        <li>Filter the database of exercises by relevant metrics.</li>
                        <li>Calculate and display relevant aggregated metrics from the user's built routine.</li>
                        
                    </ul>


                   
                    </div>
            </div>


            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">Work<span className="fst-italic">ing</span> with React</h2>
                <p className="text-center">View and interact with the app, live <span className="fw-semibold bright-orange"><a href="https://dd7bya.github.io/uiux-development/"><u>[here]</u></a></span>.</p>
                <div className="section-body mg-center fix-width">
                <p className="fw-semibold smaller-header bright-orange section-body">Interface Design</p>
                    <p>
                        Keeping the users' needs in mind, I designed an interface that would facilitate their typical activities when using Bootcamp Builder.
                        </p>
                        <ul>
                        <li>Exercises are displayed in cards that group together all relevant information.</li>
                        <li>Exercises are depicted both visually and textually to allow for quick understanding and action by the user.</li>
                            <li>An "action bar" that contains sort and filter functions in drop-down menus. The use of dropdown menus helps to prevent overcrowding the page with text.</li>
                            <li>A fixed clipboard icon that reveals the user's built routine upon clicking. A hidden, revealable "cart" helps prevent overcrowding the page.</li>
                            <li>"Add to workout" buttons are a bright, blue button that is easy to spot and target, allowing quicker action for the user.</li>
                            <li>"Delete" buttons in "cart" are smaller and somewhat difficult to target to discourage frequent accidental deletion.</li>
                            
                           
                        </ul>
                    
                     <div className="flex-standard section-body">
                        <img src={process.env.PUBLIC_URL + "/images/bBuilder-interface-1.png"} alt="" className="img-frame-sharp flex-section-half"></img>
                        <img src={process.env.PUBLIC_URL + "/images/bBuilder-interface-2.png"} alt="" className="img-frame-sharp flex-section-half"></img>
                    </div>

                    <p className="fw-semibold smaller-header bright-orange section-body">User Interaction</p>
                    <p>
                        With the use of components, props, and state hooks in React, the Bootcamp Builder interface allows for user interaction and behaves as planned.
                        </p>

                    <div className="flex-standard section-body">
                        <div className="flex-section-half"><p className="fst-italic caption-text">Filter and sort database of exercises.</p>
                        <img src={process.env.PUBLIC_URL + "/images/bBuilder-gif-1.gif"} alt="" className="img-frame-sharp"></img>
                        </div>
                        <div className="flex-section-half"><p className="fst-italic caption-text">Add and remove exercises from "cart".</p>
                        <img src={process.env.PUBLIC_URL + "/images/bBuilder-gif-2.gif"} alt="" className="img-frame-sharp"></img>
                        </div>
                    </div>


                            
                    
                </div>
            </div>

            <div className="section">
                <h2 className="display-6 secondaryFont text-center secondary-header">A Work in Prog<span className="fst-italic">ress</span></h2>
                <div className="section-body fix-width mg-center">
                    <p>
                        While I achieved the initial goals that I had set for myself when beginning this passion project, it currently has many missing components that prevent it from functioning as an effective application.<br></br><br></br>
                        Some planned features for the future include:
                    </p>
                    <ul>
                        <li>Creating a larger and more varied database of exercises.</li>
                        <li>Track and aggregate metrics other than calories.</li>
                        <li>Include an instructional video and textual instructions for each exercise.</li>
                        <li>Program a timer or an audio-guided session that begins when "Get started!" is pressed.</li>
                        <li>Improve the interface so that it is more visually impactful.</li>
                    </ul>
                    </div>
                
                
                
            </div>
            <div className="footer"></div>



        </div>
    );
}